import i18n from '@/i18n';

import { parse, isValid, setDay, getDay } from 'date-fns';
import { formatInTimeZone, toDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { getIntervalDate } from '../businessTime/intervals.js';
import { getTimezone } from '../timezone.js';

export { startOf } from './startOf.js';
export { endOf } from './endOf.js';
export { eachOfInterval } from './eachOfInterval.js';
export { isSame } from './isSame.js';

export function convertZonedDateToBackendDate(
  dateString,
  format = 'yyyy-MM-dd HH:mm'
) {
  if (!dateString) return dateString;

  const date = parse(dateString, format, new Date());

  if (!isValid(date)) {
    throw new Error(i18n.global.t('responses.0076'));
  }

  return formatInTimeZone(date, 'Europe/Amsterdam', format);
}

export function createZonedDateFromUtcDate(dateString) {
  if (!dateString) return dateString;

  return toDate(dateString, { timeZone: 'UTC' });
}

const TIMEZONE_UTC = "UTC";
const TIMEZONE_CLIENT = getTimezone();

export function getZonedTimeFromUtcTime({ date, time }) {
  return zonedTimeToUtc(getIntervalDate(date, time, { timezone: TIMEZONE_UTC }), TIMEZONE_CLIENT);
}

export function getUtcTimeFromZonedTime({ date, time }) {
  return utcToZonedTime(getIntervalDate(date, time, { timezone: TIMEZONE_CLIENT }), TIMEZONE_UTC);
}

export function getZonedDayFromUtcDay({ date, day, time }) {
  return getDay(getZonedTimeFromUtcTime({ date: setDay(date, day), time }));
}

export function getUtcDayFromZonedDay({ date, day, time }) {
  return getDay(getUtcTimeFromZonedTime({ date: setDay(date, day), time }));
}

export function formatRegistrationTime(time) {
  const [hours, minutes] = time.split(':').map(Number);

  const now = new Date();

  const utcDate = new Date(Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    hours,
    minutes,
    0,
    0
  ));

  const userTimeZone = getTimezone();

  const formatter = new Intl.DateTimeFormat([], {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: userTimeZone,
    hour12: false,
  });

  return formatter.format(utcDate);
}
